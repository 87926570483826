@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.slick-arroe {
  margin-left: -10px !important;
}
.slick-next:before {
  margin-left: -10px !important;
}

.odd-border1 {
  border-top: 2px dashed;
  border-left: 2px dashed;
  border-right: 2px dashed;
  border-bottom: 2px dashed;
  border-radius: 2%;
  border-color: rgba(
    14,
    119,
    137,
    1
  ); /* Customize the dashed border as needed */
}

.odd-border {
  border-top: 2px dashed;
  border-left: 2px dashed;
  border-right: 2px dashed;
  border-radius: 2%;
  border-color: rgba(
    14,
    119,
    137,
    1
  ); /* Customize the dashed border as needed */
}

.slick-dots li button:before {
  content: "_" !important;
  color: rgba(14, 119, 137, 1) !important;
  font-size: 36px !important;
  font-weight: bolder !important;
  line-height: 30px !important;
}
@media screen and (max-width: 480px) {
  .slick-dots li button:before {
    line-height: 10px !important;
  }
}

.slick-slide > div {
  margin: 0 10px !important;
}
.slick-prev {
  display: block;
}
.dot-list {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #555;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-it.slide-in {
  animation: slideIn 1s ease forwards;
}

.animate-it.slide-out {
  animation: slideOut 1s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* Loader Animation Case Studies */
@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.animate-wave {
  animation: wave 1s linear infinite;
}

/* Loader Animation Rainbow Case Studies */
@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(calc(250px - 20px));
  }
  65% {
    transform: translateX(calc(250px - 20px));
  }
  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}

.animate-loader {
  animation: loader 3s ease-in-out infinite;
}

.animate-loading-text:after {
  content: "Loading";
  animation: loading-text 3s infinite;
}

/* Buttons Animation for Cards */
/* Keyframe animations for bounce effects */
@keyframes bounce-left {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

@keyframes bounce-right {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

/* Tailwind CSS classes for animations */
.animate-bounce-left {
  animation: bounce-left 1s infinite;
}

.animate-bounce-right {
  animation: bounce-right 1s infinite;
}

.slick-arrow.slick-next.animate-bounce-right.custom-arrow-right:before {
  content: "→";
  color: rgba(14, 119, 137, 1);
  font-size: 48px;
}

.slick-arrow.slick-prev.animate-bounce-left.custom-arrow-left:before {
  content: "←";
  color: rgba(14, 119, 137, 1);
  font-size: 48px;
}
