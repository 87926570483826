/* WhyWeWork.css */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}

.animate-slide-in {
  animation: slideIn 1s forwards;
}

.animate-slide-out {
  animation: slideOut 1s forwards;
}
